"use client";

import Link from "next/link";
import { AwesomeButton } from "react-awesome-button";
import "./try-lavareach-button.css";

export const TryLavaReachButton = () => {
  return (
    <Link href="/dashboard" className="z-20">
      <AwesomeButton type="primary">Try LavaReach for Free</AwesomeButton>
    </Link>
  );
};
