import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function numberWithCommas(x: string | null | undefined) {
  if (!x) return null;
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function uniqueItems<T>(arr: T[]) {
  return Array.from(new Set(arr));
}

// Parse a date in yyyy-mm-dd format
export function parseSimpleDate(date: string | null | undefined): Date | null {
  if (!date) return null;

  const formatDate = date.split(" ")[0];

  const sections = formatDate.split("-");
  let returnDate = null;

  if (sections.length === 3) {
    returnDate = new Date(date);
    returnDate.setFullYear(
      parseInt(sections[0]),
      parseInt(sections[1]) - 1,
      parseInt(sections[2])
    );
  }

  return returnDate;
}

// Parse a date in MM/DD/YYYY format
export function parseSerpAPIDate(date: string): Date | null {
  const sections = date.split("/");
  let returnDate = null;

  if (sections.length === 3) {
    returnDate = new Date();
    returnDate.setFullYear(
      parseInt(sections[2]),
      parseInt(sections[0]) - 1,
      parseInt(sections[1])
    );
  }

  return returnDate;
}

export function calendarFormat(date: Date) {
  return date.toISOString().split("T")[0];
}

export function cleanSearchParam(url: string) {
  try {
    // Create a new URL object
    const cleanUrl = new URL(url);

    // Clear the search (query string) part of the URL
    cleanUrl.search = "";

    // Return the cleaned URL as a string
    return cleanUrl.toString();
  } catch (e) {
    // If an error occurs, return the original URL
    return url;
  }
}

/**
 * @param date a Date or string representing a date
 * @returns a string in the same format as "Sun, Aug 11, 2024, 2:15:30 PM"
 */
export function formatDateTime(date: Date | string | null | undefined): string {
  if (!date) return "";

  // "en-US" is the default locale
  const locales = ["en-US", "en-CA", "en"];

  return new Date(date).toLocaleTimeString(locales, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
}

/**
 * The same as formatDateTime except it doesn't include the time
 */
export function formatDate(date: Date | string | null | undefined): string {
  if (!date) return "";

  // "en-US" is the default locale
  const locales = ["en-US", "en-CA", "en"];

  return new Date(date).toLocaleDateString(locales, {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
}

export function dayDifference(date1: Date, date2: Date) {
  const msPerDay = 1000 * 60 * 60 * 24;
  const diffInMs = Math.abs(date2.getTime() - date1.getTime());
  return Math.round(diffInMs / msPerDay);
}

export function chunkArray(array: unknown[], chunkSize: number) {
  const results = [];

  while (array.length) {
    results.push(array.splice(0, chunkSize));
  }

  return results;
}

export function cleanName(name: string) {
  // remove quotation marks
  return name.replaceAll(/"/g, "");
}

export function padArrayToLength<T>(arr: T[], length: number, value: T) {
  if (arr.length >= length) return arr;
  return arr.concat(Array(length - arr.length).fill(value));
}

export function cleanColumnKey(name: string) {
  return name
    .replaceAll(".", "")
    .replaceAll("|", " ")
    .replaceAll(`"`, "")
    .replaceAll("'", "")
    .trim();
  // .toLowerCase();
}

export function mergeExtraTriggers(
  extraTriggers: string[],
  newTriggerKey: string,
  newTriggerValue: string
): string[] {
  const cleanedKey = cleanColumnKey(newTriggerKey);
  const triggerKeys = extraTriggers.map((trigger) => trigger.split("|")[0]);

  if (!triggerKeys.includes(cleanedKey)) {
    return extraTriggers.concat(`${cleanedKey}|${newTriggerValue}`);
  } else {
    return extraTriggers.map((trigger) => {
      const key = trigger.split("|")[0];
      if (key === cleanedKey) {
        return `${key}|${newTriggerValue}`;
      } else {
        return trigger;
      }
    });
  }
}

export function mergeExtraTriggersWithArray(
  extraTriggers: string[] | null,
  newTriggers: {
    key: string;
    value: string;
  }[]
): string[] {
  return newTriggers.reduce(
    (acc, { key, value }) => mergeExtraTriggers(acc, key, value),
    extraTriggers ?? []
  );
}

export function removeExtraTriggers(
  extraTriggers: string[],
  removeTriggerKey: string
): string[] {
  return extraTriggers.filter(
    (trigger) => trigger.split("|")[0] !== removeTriggerKey
  );
}

export function renameExtraTriggers(
  extraTriggers: string[],
  triggerKey: string,
  newTriggerKey: string
): string[] {
  return extraTriggers.map((trigger) => {
    const key = trigger.split("|")[0];
    if (key === triggerKey) {
      return `${newTriggerKey}|${trigger.split("|")[1]}`;
    } else {
      return trigger;
    }
  });
}

export function formatSeconds(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
}

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

const localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
const nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

export function isUrl(string: string) {
  if (typeof string !== "string") {
    return false;
  }

  const match = string.match(protocolAndDomainRE);
  if (!match) {
    return false;
  }

  const everythingAfterProtocol = match[1];
  if (!everythingAfterProtocol) {
    return false;
  }

  if (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true;
  }

  return false;
}

export function isWebsite(string: string) {
  return string.includes(".") && !string.includes(" ");
}

export function isEmailSubjectFromBouncedEmail(subject: string) {
  return (
    subject.includes("couldn't be delivered") ||
    subject.includes("Delivery has failed") ||
    subject.includes("could not be delivered") ||
    subject.includes("delivery failure") ||
    subject.includes("able to deliver your message") ||
    subject.includes("Delivery Status Notification") ||
    subject.includes("Recipient address rejected") ||
    subject.includes("Delivery to the following recipient failed") ||
    subject.includes("Delivery to the following address failed") ||
    subject.includes("wasn't delivered") ||
    subject.includes("Undeliverable") ||
    subject.includes("fatal errors")
  );
}

export function isEmailBodyFromBouncedEmail(bodyString: string) {
  return (
    bodyString.includes("couldn't be delivered") ||
    bodyString.includes("Delivery has failed") ||
    bodyString.includes("delivery failure") ||
    bodyString.includes("could not be delivered") ||
    bodyString.includes("able to deliver your message") ||
    bodyString.includes("Delivery Status Notification") ||
    bodyString.includes("Recipient address rejected") ||
    bodyString.includes("Delivery to the following recipient failed") ||
    bodyString.includes("Delivery to the following address failed") ||
    bodyString.includes("wasn't delivered") ||
    bodyString.includes("Undeliverable") ||
    bodyString.includes("Access denied") ||
    bodyString.includes("address rejected")
  );
}

export function isValidJson(jsonString: string) {
  if (!jsonString.includes("{") || !jsonString.includes("}")) {
    return false;
  }
  try {
    JSON.parse(jsonString);
    return true;
  } catch (e) {
    return false;
  }
}

export function getFieldOrEmpty(field: string | null | undefined): string {
  return (field?.length ?? 0) > 0 ? field ?? "" : "";
}

// Returns an array of common elements between two arrays
export function intersection<T>(a: T[], b: T[]) {
  const result: T[] = [];
  while (a.length > 0 && b.length > 0) {
    if (a[0] < b[0]) {
      a.shift();
    } else if (a[0] > b[0]) {
      b.shift();
    } /* they're equal */ else {
      result.push(a.shift()!);
      b.shift();
    }
  }

  return result;
}

// Returns an array of elements that are in the first array but not in the second
export function difference<T>(a: T[], b: T[]) {
  return a.filter((value) => !b.includes(value));
}

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function randIntRange(min: number, max: number) {
  return min + Math.floor(Math.random() * (max - min));
}

export function splitArrayIntoChunks(array: string[], chunkSize: number) {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    chunks.push(chunk);
  }
  return chunks;
}

export function splitArraySequentially<T>({
  array,
  numberOfArrayToSplit,
}: {
  array: T[];
  numberOfArrayToSplit: number;
}) {
  const chunks = Array.from({ length: numberOfArrayToSplit }, () => [] as T[]);

  for (let i = 0; i < array.length; i++) {
    const chunkIndex = i % numberOfArrayToSplit;
    chunks[chunkIndex].push(array[i]);
  }

  return chunks;
}

export function isNullOrNA(value: string | null | undefined) {
  return value == null || value === "N/A" || value === "";
}

export function addOrdinalSuffix(number: number) {
  const j = number % 10,
    k = number % 100;
  if (j == 1 && k != 11) {
    return number + "st";
  }
  if (j == 2 && k != 12) {
    return number + "nd";
  }
  if (j == 3 && k != 13) {
    return number + "rd";
  }
  return number + "th";
}
