"use client";

import Image from "next/image";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import Particles from "./particles";
// Import Swiper styles
import "swiper/css";
import "./swiper.css";

export default function Clients() {
  return (
    <section>
      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        {/* Particles animation */}
        <div className="absolute inset-0 max-w-6xl mx-auto px-4 sm:px-6">
          <Particles className="absolute inset-0 -z-10" quantity={5} />
        </div>

        <div>
          <h4 className="text-base sm:text-sm md:text-base bg-clip-text text-center font-semibold text-text-landing pb-4">
            TRUSTED BY
          </h4>

          <div className="overflow-hidden">
            {/* Carousel built with Swiper.js [https://swiperjs.com/] */}
            {/* * Custom styles in src/css/additional-styles/theme.scss */}
            <div className="clients-carousel .triggers-carousel swiper-container relative before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none after:absolute after:inset-0 after:left-auto after:w-32 after:z-10 after:pointer-events-none">
              <Swiper
                className="swiper-wrapper"
                spaceBetween={0}
                slidesPerView={5}
                centeredSlides={true}
                loop={true}
                freeMode={{
                  enabled: false,
                }}
                speed={4000}
                autoplay={{
                  delay: 0,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide>
                  <Image
                    src={"/clients/leonardo.png"}
                    alt="Leonardo"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/remote.png"}
                    alt="Remote"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/market-edge-partners.png"}
                    alt="Market Edge Partners"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/ef.png"}
                    alt="EF"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/mgr.png"}
                    alt="MGR"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/wagepoint.png"}
                    alt="WagePoint"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/jonas.png"}
                    alt="Jonas"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/ziosk.png"}
                    alt="Ziosk"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/pharmethod.png"}
                    alt="Pharmethod"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <Image
                    src={"/clients/ali-insurance.png"}
                    alt="Ali Insurnace"
                    width={247}
                    height={76}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
